<template>
  <div>
    <b-modal
      id="modal_hostigamiento_formulario_precalificar"
      title="Informe de pre calificación del comité"
      size="xl"
      no-close-on-backdrop
      :hide-footer="true"
      no-close-on-esc
      hide-header-close
    >
      <b-card
        title="INFORME DE PRE CALIFICACIÓN DEL COMITÉ FRENTE AL HOSTIGAMIENTO SEXUAL EN LA UNIA"
        class="mx-2"
      >
        <p>Habiendo sido notificados con fecha dd/mm/aaaa, de la queja/denuncia de presunto hostigamiento sexual presentada por Nombres y apellidos de la presunta víctima, en torno a los hechos consignados en la siguiente sección, y luego de la realización de la investigación correspondiente y análisis de los medios probatorios disponibles, el Comité Frente al Hostigamiento Sexual - UNIA, dentro del plazo correspondiente, procede a emitir el siguiente informe</p>
        <h3>1. Descripción de los hechos denunciados:</h3>
        <p>
          Con fecha dd/mm/aaaa, el/la Sr./Sra. Nombres y apellidos de la presunta víctima, comunicó los siguientes hechos, los cuales se vincularían a una posible situación de hostigamiento sexual:
        </p>

        <br>
        <quill-editor
          v-model="dataForm.descripcionHechos"
        />
        <!-- segunda parte -->
        <h3 class="mt-2">
          2. Descargos y respuesta:
        </h3>
        <p>Ante los hechos denunciados, el/la Sr./Sra. Nombres y Apellidos del presunto hostigador ha alegado, en respuesta a la queja interpuesta, lo siguiente:</p>

        <br>
        <quill-editor
          v-model="dataForm.descargosRespuestas"
        />
        <!-- tercera parte -->
        <h3 class="mt-2">
          3. Descripción y valoración de los medios probatorios:
        </h3>
        <p>Para acreditar sus alegaciones, ambas partes han procedido a presentar los siguientes
          medios probatorios, los cuales han merecido la valoración que procedemos a describir
          :</p>

        <br>
        <quill-editor
          v-model="dataForm.descripcionValoracion"
        />
        <!-- cuarto parte -->
        <h3 class="mt-2">
          4. Conclusión: propuesta de sanción o archivamiento
        </h3>
        <p>Teniendo en cuenta los hechos denunciados, los descargos presentados, así como los
          medios probatorios, el Comité ha llegado a las siguientes conclusiones      :</p>

        <br>
        <quill-editor
          v-model="dataForm.conclusion"
        />
        <!-- quinto parte -->
        <h3 class="mt-2">
          5. Emisión de recomendaciones finales:
        </h3>
        <p>Teniendo en cuenta los hechos denunciados, los descargos presentados, así como los
          medios probatorios, el Comité ha llegado a las siguientes conclusiones      :</p>

        <br>
        <quill-editor
          v-model="dataForm.recomendaciones"
        />
        <!-- sexto parte -->
        <h3 class="mt-2">
          6. Responsables del informe:
        </h3>
        <quill-editor
          v-model="dataForm.responsables"
        />

        <b-col class="mt-1">

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1"
            variant="outline-danger"
            size="sm"
            pill
            @click="() => $bvModal.hide('modal_hostigamiento_formulario_precalificar')"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            <span class="align-middle">Salir</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-1"
            size="sm"
            pill
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="registrarPreCalificacion"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Guardar</span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            size="sm"
            pill
            @click="descargarPdf()"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            <span class="align-middle">Imprimir</span>
          </b-button>
          <b-overlay
            :show="show"
            no-wrap
          />
        </b-col>
      </b-card>

    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BCol, BButton, BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCard,
    BButton,
    BCol,
    quillEditor,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataForm: {},
      dataDenuncia: {},
    }
  },
  created() {
    this.$parent.$on('abrirModalPrecalificar', this.abrirModalPrecalificar)
  },
  methods: {
    abrirModalPrecalificar(dataDenuncia) {
      this.$bvModal.show('modal_hostigamiento_formulario_precalificar')
      this.dataDenuncia = dataDenuncia
      this.dataForm.descripcionHechos = dataDenuncia.pre_calificar_denuncia.hechos_denunciados
      this.dataForm.descargosRespuestas = dataDenuncia.pre_calificar_denuncia.descargos_respuestas
      this.dataForm.descripcionValoracion = dataDenuncia.pre_calificar_denuncia.valoracion_medios_probatorios
      this.dataForm.conclusion = dataDenuncia.pre_calificar_denuncia.conclusion
      this.dataForm.recomendaciones = dataDenuncia.pre_calificar_denuncia.recomendacion
      this.dataForm.responsables = dataDenuncia.pre_calificar_denuncia.responsables
    },
    registrarPreCalificacion() {
      const DATA = {
        denunciaId: this.dataDenuncia.id,
        data: {
          hechos_denunciados: this.dataForm.descripcionHechos,
          descargos_respuestas: this.dataForm.descargosRespuestas,
          valoracion_medios_probatorios: this.dataForm.descripcionValoracion,
          conclusion: this.dataForm.conclusion,
          recomendacion: this.dataForm.recomendaciones,
          responsables: this.dataForm.responsables,
        },
      }

      store.dispatch('denuncias-store-module/postRegistroPrecalificar', DATA).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: '¡Registro guardado!',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$emit('actualizarTablaDenuncias')
      })
    },
    descargarPdf() {
      this.show = true
      this.$http.get(`/servicio-social/prevencion-hostigamiento/denuncias/${this.dataDenuncia.id}/precalificar_pdf`,
        {
          params: { url_pdf: `${window.location.origin}/f/servicio-social/denuncias/${this.dataDenuncia.id}/pdf` },
          responseType: 'blob', // important
        })
        .then(response => {
          this.show = false
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', `InformePrecalificacion${this.dataDenuncia.id}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
    },

  },
  setup() {
    const text = ref(null)
    return { text }
  },
}
</script>

<style lang="scss" >
.ql-editor {
    padding: 40px 15px;
}
.ql-link, .ql-image, .ql-video{
    visibility: hidden;
}
</style>
