<template>
  <div>
    <b-modal
      id="modal_cargar_archivo_denuncia"
      :title="`Cargar archivo ${tituloModal}`"
      size="md"
      no-close-on-backdrop
      :hide-footer="true"
      no-close-on-esc
      hide-header-close
    >
      <b-card
        no-body
        class="border mb-1 pr-1 mt-0"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="FileTextIcon"
              size="16"
            />
            <span class="align-middle ml-50">Cargar documento</span>
          </b-card-title>
        </b-card-header>
        <b-card-body>

          <b-form
            enctype="multipart/form-data"
            @submit="formSubmit"
          >
            <b-row>
              <b-col
                lg="12"
                md="12"
                xs="12"
                class="mb-1"
              >

                <b-form-file
                  v-model="form.file"
                  size="sm"
                  :browse-text="'Seleccionar archivo'"
                  :placeholder="'No se eligió archivo'"
                  required
                  accept=".pdf"
                >
                  <template
                    slot="file-name"
                    slot-scope="{ names }"
                  >
                    <b-badge variant="primary">
                      {{ names[0] }}
                    </b-badge>
                  </template>
                </b-form-file>

              </b-col>

              <b-col
                lg="12"
                md="12"
                xs="12"
              >
                <b-button
                  variant="primary"
                  class=" px-1"
                  size="sm"
                  style="padding: 8px 10px"
                  block
                  type="submit"
                >
                  CARGAR ARCHIVO
                  <feather-icon
                    icon="SaveIcon"
                    size="14"
                    class="m-0 p-0"
                  />
                </b-button>
              </b-col>

            </b-row>
          </b-form>
        </b-card-body>
      </b-card>

      <b-button
        variant="outline-secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$bvModal.hide('modal_cargar_archivo_denuncia')"
      >
        Cerrar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge, BFormFile, BForm, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BRow, BCol, BBadge, BButton, BFormFile, BForm, BCardHeader, BCardTitle, BCardBody,
  },

  data() {
    return {
      isBusy: false,
      denunciaId: 0,
      tituloModal: '',
      rutaPost: '',
      form: {
        file: null,
      },

    }
  },
  created() {
    this.$parent.$on('abrirModalArchivoDenuncia', this.abrirModalArchivoDenuncia)
  },
  methods: {
    abrirModalArchivoDenuncia(item) {
      this.denunciaId = item.id
      this.tituloModal = item.tituloModal
      this.rutaPost = item.rutaPost
      this.$bvModal.show('modal_cargar_archivo_denuncia')
    },
    formSubmit(e) {
      e.preventDefault()
      const formData = new FormData()
      if (this.rutaPost === 'postArchivoPrecalificar') {
        formData.append('archivo_precalificar', this.form.file)
      } else if (this.rutaPost === 'postArchivoResolucion') {
        formData.append('archivo_resolucion', this.form.file)
      }
      formData.append('_method', 'put')

      const data = {
        denunciaId: this.denunciaId,
        data: formData,
      }
      store.dispatch('denuncias-store-module/updateArchivosDenuncia', data)
        .then(() => {
          this.$emit('actualizarTablaDenuncias')
          this.$bvModal.hide('modal_cargar_archivo_denuncia')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Archivo cargado correctamente!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            error.response.data.error.archivo_resultado.forEach(value => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: '¡Advertencia!',
                  text: value,
                  icon: 'XOctagonIcon',
                  variant: 'warning',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Falló la carga, error 500',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
