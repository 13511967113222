import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDenuncias(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/prevencion-hostigamiento/denuncias', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postRegistroPrecalificar(ctx, { denunciaId, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/prevencion-hostigamiento/denuncias/${denunciaId}/precalificar`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateArchivosDenuncia(ctx, { denunciaId, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/prevencion-hostigamiento/denuncias/${denunciaId}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },

}
