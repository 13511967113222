<template>
  <div>
    <ModalActividadSeguimiento @actualizarSeguimiento="actualizarSeguimientoDenuncia" />
    <b-modal
      id="modal_seguimiento_denuncia"
      title="Seguimiento Denuncia"
      size="lg"
      :hide-footer="true"
      scrollable
    >
      <b-card class="mb-1 pl-2 border-left-info border-right-info border-top-info">
        <b-row>
          <b-col
            md="8"
            sm="12"
          >
            <div>
              <h4 class="text-primary">
                SEGUIMIENTO DENUNCIA HOSTIGAMIENTO
              </h4>
              <ul>
                <li>
                  <strong>Denunciante:</strong>  {{ itemDenuncia.denunciante ? itemDenuncia.denunciante : '' }}
                </li>
                <li>
                  <strong>Denunciado(s): </strong> {{ itemDenuncia.denunciado ? itemDenuncia.denunciado : '' }}
                </li>
              </ul>
            </div>
          </b-col>
          <b-col
            md="4"
            sm="12"
          >
            <div class="d-flex justify-content-end mb-2">
              <b-button
                variant="success"
                size="sm"
                @click="nuevaActividad"
              >
                Adicionar actividad
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        class="mt-1 pl-2 border-left-info border-right-info border-bottom-info"
      >
        <div v-if="actividades">
          <app-timeline>
            <app-timeline-item
              v-for="(actividad, index) in actividades"
              :key="index"
              variant="primary"
              class="hoverActividad pt-1"
              :style="index%2 === 0 ? 'background:#FAFAFA;' : ''"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 class="text-primary">
                  {{ actividad.actividad }}
                </h6>
                <small class="text-muted">
                  {{ new Intl.DateTimeFormat('es-PE',{month: 'long', day:'numeric', year:'numeric'}).format(new Date(actividad.fecha)) }}

                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                        class="align-middle text-primary"
                      />
                    </template>
                    <b-dropdown-item
                      @click="editarActividad(actividad)"
                    >
                      <feather-icon icon="EyeIcon" />
                      <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="eliminarActividad(actividad.id)"
                    >
                      <feather-icon icon="EyeIcon" />
                      <span class="align-middle ml-50">Eliminar</span>
                    </b-dropdown-item>

                  </b-dropdown>
                </small>

              </div>
              <p>{{ actividad.descripcion }}</p>
              <span
                v-for="(item, index2) in actividad.evidencias"
                :key="index2"
                class="ml-1"
              >
                <a
                  :href="item.archivo"
                  target="_blank"
                  style="padding-top:8px; padding-bottom:8px;"
                  class="bg-light-success px-1 rounded"
                >
                  <b-img
                    :src="require('@/assets/images/icons/doc.png')"
                    height="auto"
                    width="20"
                    variant="success"
                    class="mr-1"
                  />
                  <span
                    class="align-bottom"
                    style="font-size:12px;"
                  >{{ item.nombre }}</span>
                </a>
              </span>

            </app-timeline-item>

          </app-timeline>
        </div>

        <div v-if="actividades.length === 0">
          <h4 class="text-center text-danger">
            No existen actividades para mostrar
          </h4>
        </div>
      </b-card>

    </b-modal>
  </div>

</template>

<script>
import {
  BCard, BButton, BImg, BDropdown, BDropdownItem, BRow, BCol,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import Ripple from 'vue-ripple-directive'
import store from '@/store'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ModalActividadSeguimiento from './ModalActividadSeguimiento.vue'
import seguimientoStoreModule from './actividadDenunciaStoreModule'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BImg,
    ModalActividadSeguimiento,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataForm: {},
      itemDenuncia: {},
      actividades: [],
    }
  },
  created() {
    this.$parent.$on('abrirModalSeguimientoDenuncia', this.abrirModalSeguimientoDenuncia)
  },
  methods: {

    abrirModalSeguimientoDenuncia(item) {
      this.itemDenuncia = item
      this.actualizarSeguimientoDenuncia()
      this.$bvModal.show('modal_seguimiento_denuncia')
    },
    nuevaActividad() {
      this.$emit('nuevaActividadSeguimiento', this.itemDenuncia)
    },
    editarActividad(actividad) {
      this.$emit('editarActividadSeguimiento', actividad)
    },
    actualizarSeguimientoDenuncia() {
      const idDenuncia = this.itemDenuncia.id
      store.dispatch('seguimiento-store-module/getActividades', idDenuncia).then(res => {
        this.actividades = res.data.data
      })
    },

  },
  setup() {
    const MODAL_SEGUIMIENTO_APP_STORE_MODULE_NAME = 'seguimiento-store-module'

    // Register module
    if (!store.hasModule(MODAL_SEGUIMIENTO_APP_STORE_MODULE_NAME)) store.registerModule(MODAL_SEGUIMIENTO_APP_STORE_MODULE_NAME, seguimientoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODAL_SEGUIMIENTO_APP_STORE_MODULE_NAME)) store.unregisterModule(MODAL_SEGUIMIENTO_APP_STORE_MODULE_NAME)
    })

    return {
      seguimientoStoreModule,

    }
  },
}
</script>

<style lang="scss" scoped>
.hoverActividad:hover {
  // border-radius: 0px 10px 10px 10px;
  border-right: 2px solid green;
  border-left: 2px solid green;
  // background: #ecfffe;
}
</style>
