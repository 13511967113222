<template>
  <div>
    <b-modal
      id="modal_actividad_hostigamiento"
      :hide-footer="true"
      size="xl"
      no-close-on-backdrop
      :header-bg-variant="esGuardar ? 'primary' : 'warning'"
    >
      <template
        #modal-header
      >
        <span class="text-white">
          <strong class="float-left">
            {{ tituloModal }}
          </strong>
        </span>
        <span class="float-right">
          <b-button

            variant="gradient-danger"
            @click="cerrarModal = true; formSubmitted(); $bvModal.hide('modal_actividad_hostigamiento')"
          >
            <feather-icon
              icon="XIcon"
            />
          </b-button>
        </span>
      </template>

      <div v-if="cerrarModal === false">
        <form-wizard
          next-button-text="Guardar y siguiente"
          back-button-text="Anterior"
          color="#06634c"
          :title="null"
          :subtitle="null"
          layout="horizontal"
          finish-button-text="Terminar Proceso"
          class="wizard-horizontal mb-3"
          @on-complete="formSubmitted"
          @on-change="onchange"
        >
          <tab-content
            title="Registrar actividad"
            :before-change="validationFormInfo"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                />
                <b-col md="8">
                  <b-form-group
                    label="Actividad"
                    label-for="v-actividad"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="actividad"
                      rules="required"
                    >
                      <b-form-input
                        id="v-actividad"
                        v-model="actividad.actividad"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >Este campo es obligatorio</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group

                    label="Fecha"
                    label-for="v-fecha"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="fecha"
                      rules="required"
                    >
                      <b-form-input
                        id="v-fecha"
                        v-model="actividad.fecha"
                        type="date"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >El campo fecha es obligatorio</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Descripción (opcional)"
                    label-for="v-descripcion"
                  >
                    <b-form-textarea
                      id="v-descripcion"
                      v-model="actividad.descripcion"
                      placeholder="Describa aquí"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <tab-content
            title="Evidencias"
            :before-change="validationFormInfo"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Evidencias
                </h5>
                <small class="text-muted">Registro de evidencias</small>
              </b-col>
              <b-col md="5">
                <b-form-group
                  label="Nombre Evidencia"
                  label-for="nueva_evidencia"
                >
                  <b-form-input
                    id="nueva_evidencia"
                    v-model="evidenciaNueva.nombre"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Archivo"
                  label-for="archivo"
                >
                  <b-form-file
                    v-model="evidenciaNueva.archivo"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-button
                  size="sm"
                  variant="success"
                  class="mt-2"
                  @click="adicionarEvidencia()"
                >
                  <feather-icon icon="ArrowUpCircleIcon" />
                  Cargar archivo
                </b-button>
              </b-col>
              <b-col md="12">
                <b-table
                  :items="evidencias"
                  :fields="fieldEvidencias"
                  sticky-header="300px"
                  small
                  head-variant="light"
                  class="mb-3"
                >
                  <template #cell(actions)="row">
                    <b-button
                      size="sm"
                      class="mr-1"
                      variant="outline-danger"
                      @click="eliminarEvidencia(row.item.id)"
                    >
                      eliminar
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </template>
                  <template #cell(archivo)="row">
                    <a
                      :href="row.item.archivo"
                      class="btn btn-sm btn-outline-success"
                      target="_blank"
                    >ver evidencia
                      <feather-icon icon="EyeIcon" />
                    </a>
                  </template>
                </b-table>
                <br>
                <br>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { onUnmounted } from '@vue/composition-api'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  VBModal,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import actividadHostigamientoStoreModule from './actividadDenunciaStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BTable,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  data() {
    return {
      idDenuncia: 0,
      selected: [],

      actividad_id: 0,
      evidenciaNueva: {
        nombre: '',
        archivo: null,
      },

      fieldEvidencias: [
        {
          key: 'nombre', label: 'Nombre', sortable: true, sortDirection: 'asc',
        },
        {
          key: 'archivo', label: 'Evidencia', sortable: true, sortDirection: 'asc',
        },
        {
          key: 'actions', label: 'Eliminar', sortable: true, sortDirection: 'asc',
        },
      ],

      filter: null,
      required,
      actividad: {
        id: '',
        nombre: '',
        fecha: '',
        descripcion: '',
      },
      tituloModal: '',
      evidencias: [],
      esGuardar: true,
      cerrarModal: false,
    }
  },
  created() {
    this.$parent.$on('nuevaActividadSeguimiento', this.nuevaActividadSeguimiento)
    this.$parent.$on('editarActividadSeguimiento', this.editarActividadSeguimiento)
  },
  methods: {

    nuevaActividadSeguimiento(itemDenuncia) {
      this.idDenuncia = itemDenuncia.id
      this.tituloModal = 'Nueva actividad'
      this.esGuardar = true
      this.cerrarModal = false
      this.actividad.actividad = ''
      this.actividad.fecha = ''
      this.actividad.descripcion = ''
      this.selected = []
      this.evidencias = []

      this.$bvModal.show('modal_actividad_hostigamiento')
    },
    editarActividadSeguimiento(item) {
      this.tituloModal = 'Actualizar actividad'
      this.actividad_id = item.id
      this.actividad.actividad = item.actividad
      this.actividad.fecha = item.fecha
      this.actividad.descripcion = item.descripcion
      this.$bvModal.show('modal_actividad_hostigamiento')
      this.esGuardar = false
      this.cerrarModal = false
      this.selected = []
      this.evidencias = []

      this.actualizarTablaEvidencias()
    },
    guardarActividad() {
      const data = {
        idDenuncia: this.idDenuncia,
        data: this.actividad,
      }
      store.dispatch('actividades-hostigamiento-store-module/addActividadSeguimiento', data).then(response => {
        this.actividad_id = response.data.id

        this.$emit('actualizarSeguimiento')
      }).catch(() => {
        this.toast('Se recomienda actualizar el navegador', 'danger', 'Error en guardar actividad')
      })
    },
    actualizarActividad() {
      const data = {
        idActividad: this.actividad_id,
        data: this.actividad,
      }

      store.dispatch('actividades-hostigamiento-store-module/updateActividad', data).then(() => {
        this.$emit('actualizarSeguimiento')
      }).catch(() => {
        this.toast('Se recomienda actualizar el navegador', 'danger', 'Error en actualizar actividad')
      })
    },
    adicionarEvidencia() {
      if (this.evidenciaNueva.nombre === '' || this.evidenciaNueva.archivo === null) {
        this.toast('Campo nombre o archivo vacio ', 'warning', '¡Advertencia!')
        return
      }
      const formData = new FormData()
      formData.append('nombre', this.evidenciaNueva.nombre)
      formData.append('archivo', this.evidenciaNueva.archivo)
      const data = {
        actividadId: this.actividad_id,
        formData,
      }
      store.dispatch('actividades-hostigamiento-store-module/addEvidencia', data).then(() => {
        this.evidenciaNueva.nombre = ''
        this.evidenciaNueva.archivo = null
        this.toast('¡Evidencia registrada!', 'success', 'Éxito')
        this.actualizarTablaEvidencias()
        this.$emit('actualizarSeguimiento')
      }).catch(() => {
        this.toast('¡Error al cargar evidencia!', 'danger', 'Error')
      })
    },
    actualizarTablaEvidencias() {
      const data = {
        actividadId: this.actividad_id,
      }
      store.dispatch('actividades-hostigamiento-store-module/getEvidencias', data).then(res => {
        this.evidencias = res.data.data
      }).catch(() => {
        this.toast('¡Error al actualizar tabla evidencias!', 'danger', 'Error')
      })
    },
    eliminarEvidencia(id) {
      const data = {
        actividadId: this.actividad_id,
        id,
      }
      store.dispatch('actividades-hostigamiento-store-module/deleteEvidencia', data).then(res => {
        this.swalSuccess(`Evidencia ${res.data.nombre} eliminada`)
        this.actualizarTablaEvidencias()
        this.$emit('actualizarSeguimiento')
      }).catch(() => {
        this.toast('¡Error al eliminar evidencia seleccionada!', 'danger', 'Error')
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    toast(text, tipo, titulo) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titulo,
          icon: 'BellIcon',
          text,
          variant: tipo,
        },
      })
    },
    formSubmitted() {
      if (this.cerrarModal) { return }
      if (this.esGuardar === 0) {
        this.toast('¡Actividad guardado con exito!', 'success', 'Éxito')
      } else {
        this.toast('¡Actividad actualizada con exito!', 'success', 'Éxito')
      }
      this.$bvModal.hide('modal_actividad_hostigamiento')
      this.cerrarModal = true
    },
    swalSuccess(texto) {
      this.$swal({
        position: 'top-end',
        icon: 'success',
        title: texto,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    onchange(prevIndex) {
      if (this.cerrarModal) { return }
      switch (prevIndex) {
        case 0:
          if (this.esGuardar) {
            this.guardarActividad()
          } else {
            this.actualizarActividad()
          }
          // this.textoBotonNext = ''
          break
        case 1:
          break
        case 2:
          break
        default:
          break
      }
    },

  },
  setup() {
    const MODAL_ACTIVIDAD_APP_STORE_MODULE_NAME = 'actividades-hostigamiento-store-module'

    // Register module
    if (!store.hasModule(MODAL_ACTIVIDAD_APP_STORE_MODULE_NAME)) store.registerModule(MODAL_ACTIVIDAD_APP_STORE_MODULE_NAME, actividadHostigamientoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODAL_ACTIVIDAD_APP_STORE_MODULE_NAME)) store.unregisterModule(MODAL_ACTIVIDAD_APP_STORE_MODULE_NAME)
    })

    return {
      actividadHostigamientoStoreModule,

    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';

</style>
<style scoped>
 .wizard-icon-container{
   background-color: red;
 }
</style>
