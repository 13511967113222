<template>

  <div>
    <ModalFormularioRegistroDenuncia @actualizarTablaDenuncias="actualizarTablaDenuncias" />
    <ModalCargarArchivoDenuncia @actualizarTablaDenuncias="actualizarTablaDenuncias" />
    <ModalActualizarEstado @actualizarTablaDenuncias="actualizarTablaDenuncias" />
    <ModalSeguimiento />
    <ModalFormularioDenuncia />
    <b-card
      no-body
      class="mb-0 px-2"
    >
      <div class="m-2">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                size="md"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="400"
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        id="my-table"
        ref="refTablaDenuncias"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="tableColumns"
        :no-provider-filtering="true"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :height="myProvider.length < 5 ? '200px' : null"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ from + data.index }}
        </template>

        <template #cell(denunciante)="data">
          {{ data.item.denunciante }}
          <br>
          <b-button
            variant="outline-primary"
            size="sm"
            block
            class="mr-0"
            style="padding: 2px;"
            @click="abrirFormularioLectura(data.item.usuario_anonimo)"
          >
            ver denuncia
          </b-button>
        </template>
        <template #cell(denunciado)="data">

          <ul>
            <li
              v-for="(denunciadoss, index) in JSON.parse(data.item.denunciado)"
              :key="index"
            >
              {{ denunciadoss }}

            </li>
          </ul>
        </template>

        <template #cell(hostigamiento)="data">
          <b-badge
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            v-b-tooltip.hover.top="'Actualizar estado'"
            pill
            :variant="`light-${resolveEstadoVariant(data.item.hostigamiento)}`"
            class="text-capitalize"
            @click="actualizarEstadoDenuncia(data.item)"
          >
            <span style="padding:10px;">{{ data.item.hostigamiento }}</span>
          </b-badge>
        </template>

        <template #cell(archivos)="data">
          <div
            v-if="data.item.archivo_precalificar"
            style="margin-bottom: 5px;"
          >
            <b-button
              variant="outline-info"
              size="sm"
              block
              style="padding:5px;"
              :href="data.item.archivo_precalificar"
              :target="'_blank'"
            >Informe de precalificacion
              <feather-icon
                icon="ClipboardIcon"
                size="12"
                class="align-middle"
              />
            </b-button>
          </div>
          <div v-if="data.item.archivo_resolucion">
            <b-button
              variant="outline-success"
              size="sm"
              block
              style="padding:5px;"
              :href="data.item.archivo_resolucion"
              :target="'_blank'"
            >Resolución
              <feather-icon
                icon="ClipboardIcon"
                size="12"
                class="align-middle"
              />
            </b-button>
          </div>
        </template>

        <template #cell(accion)="data">

          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            v-b-tooltip.hover.top="'Hacer seguimiento'"
            variant="flat-primary"
            class="btn-icon rounded-circle border-primary"
            style="padding:5px;"
            @click="hacerSeguimiento(data.item)"
          >
            <feather-icon icon="TrendingUpIcon" />
          </b-button>
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="align-middle text-primary"
              />
            </template>
            <b-dropdown-item
              @click="formularioPrecalificar(data.item)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Formulario pre calificar</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="cargarArchivoPrecalificar(data.item)"
            >
              <feather-icon icon="ShareIcon" />
              <span class="align-middle ml-50">Cargar archivo pre calificar</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="data.item.archivo_precalificar ? false : true"
              @click="cargarArchivoResolucion(data.item)"
            >
              <feather-icon icon="ShareIcon" />
              <span class="align-middle ml-50">Cargar archivo resolución</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- <convocatoria-list-postulantes /> -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BSpinner, BFormSelect, BDropdown, BDropdownItem,
  BPagination, BButton, BBadge, VBTooltip,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import buzonStoreModule from './denunciasStoreModule'
import ModalFormularioRegistroDenuncia from './ModalFormularioRegistroDenuncia.vue'
import ModalCargarArchivoDenuncia from './ModalCargarArchivoDenuncia.vue'
import ModalActualizarEstado from './ModalActualizarEstado.vue'
import ModalSeguimiento from './seguimiento/ModalSeguimiento.vue'
import ModalFormularioDenuncia from './ModalFormularioDenuncia.vue'

export default {
  components: {

    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    BBadge,
    BPagination,
    BButton,
    BFormSelect,

    // externs
    ModalFormularioRegistroDenuncia,
    ModalCargarArchivoDenuncia,
    ModalActualizarEstado,
    ModalSeguimiento,
    ModalFormularioDenuncia,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 500,
      pageOptions: [50, 100, 300, 500],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],
    }
  },
  methods: {
    actualizarTablaDenuncias() {
      this.filter = null
      this.$refs.refTablaDenuncias.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('denuncias-store-module/getDenuncias', { queryParams: ctx })

      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data

        const items = data
        this.totalRows = total || 0

        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },
    formularioPrecalificar(item) {
      this.$emit('abrirModalPrecalificar', item)
    },
    cargarArchivoPrecalificar(item) {
      const itemData = item
      itemData.tituloModal = 'Pre calificar'
      itemData.rutaPost = 'postArchivoPrecalificar'

      this.$emit('abrirModalArchivoDenuncia', itemData)
    },
    cargarArchivoResolucion(item) {
      const itemData = item
      itemData.tituloModal = 'resolución'
      itemData.rutaPost = 'postArchivoResolucion'
      this.$emit('abrirModalArchivoDenuncia', itemData)
    },
    actualizarEstadoDenuncia(item) {
      if (item.archivo_resolucion) {
        this.$emit('abrirModalActualizarEstado', item)
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: '¡Advertencia!',
            text: 'No existe archivo de resolución',
            icon: 'CheckCircleIcon',
            variant: 'warning',
          },
        })
      }
    },
    hacerSeguimiento(item) {
      this.$emit('abrirModalSeguimientoDenuncia', item)
    },
    abrirFormularioLectura(usuarioAnonimo) {
      this.$emit('abrirModalFormularioLectura', usuarioAnonimo)
    },
  },
  setup() {
    const DENUNCIAS_APP_STORE_MODULE_NAME = 'denuncias-store-module'

    // Register module
    if (!store.hasModule(DENUNCIAS_APP_STORE_MODULE_NAME)) store.registerModule(DENUNCIAS_APP_STORE_MODULE_NAME, buzonStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DENUNCIAS_APP_STORE_MODULE_NAME)) store.unregisterModule(DENUNCIAS_APP_STORE_MODULE_NAME)
    })

    const tableColumns = [

      { key: 'index', label: 'N°' },
      { key: 'denunciante', label: 'denunciante', sortable: true },
      { key: 'denunciado', label: 'denunciado(s)', sortable: true },
      { key: 'archivos', label: 'archivos', sortable: true },
      // { key: 'archivo_resolucion', label: 'resolución', sortable: true },
      {
        key: 'ultima_actividad', label: 'última actividad', sortable: true, class: 'text-center',
      },
      {
        key: 'hostigamiento', label: 'estado', sortable: true, class: 'text-center',
      },
      { key: 'accion', label: 'acción', sortable: true },
    ]

    const resolveEstadoVariant = status => {
      if (status === 'PENDIENTE') return 'warning'
      if (status === 'SI') return 'success'
      if (status === 'NO') return 'danger'

      return 'primary'
    }

    return {
      buzonStoreModule,
      tableColumns,
      avatarText,
      resolveEstadoVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
