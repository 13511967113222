<template>
  <div>
    <b-modal
      id="modal_actualizar_estado"
      title="Estado hostigamiento"
      size="md"
      no-close-on-backdrop
      :hide-footer="true"
      no-close-on-esc
      hide-header-close
    >
      <b-card
        no-body
        class="border mb-1 pr-1 mt-0"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="FileTextIcon"
              size="16"
            />
            <span class="align-middle ml-50">Actualizar estado hostigamiento</span>
          </b-card-title>
        </b-card-header>
        <b-card-body>

          <b-form
            enctype="multipart/form-data"
            @submit="formSubmit"
          >
            <b-row>
              <b-col class="py-1">
                <b-form-group>
                  <b-form-radio-group
                    v-model="selectedEstado"
                    :options="optionsEstado"
                    class="demo-inline-spacing"
                    name="radio-inline"
                  />
                </b-form-group>
              </b-col>

              <b-col
                lg="12"
                md="12"
                xs="12"
              >
                <b-button
                  variant="primary"
                  class=" px-1"
                  size="sm"
                  style="padding: 8px 10px"
                  block
                  type="submit"
                >
                  ACTUALIZAR ESTADO
                  <feather-icon
                    icon="SaveIcon"
                    size="14"
                    class="m-0 p-0"
                  />
                </b-button>
              </b-col>

            </b-row>
          </b-form>
        </b-card-body>
      </b-card>

      <b-button
        variant="outline-secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$bvModal.hide('modal_actualizar_estado')"
      >
        Cerrar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BForm, BCardHeader, BCardTitle, BCardBody, BFormRadioGroup, BFormGroup,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormRadioGroup,
    BFormGroup,
  },

  data() {
    return {
      denunciaId: 0,
      selectedEstado: null,
      optionsEstado: [
        { text: 'PENDIENTE', value: 'PENDIENTE' },
        { text: 'NO', value: 'NO' },
        { text: 'SI', value: 'SI' },
      ],

    }
  },
  created() {
    this.$parent.$on('abrirModalActualizarEstado', this.abrirModalActualizarEstado)
  },
  methods: {
    abrirModalActualizarEstado(item) {
      this.$bvModal.show('modal_actualizar_estado')
      this.denunciaId = item.id
      this.selectedEstado = item.hostigamiento
    },
    formSubmit(e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('hostigamiento', this.selectedEstado)
      formData.append('_method', 'put')

      const data = {
        denunciaId: this.denunciaId,
        data: formData,
      }
      store.dispatch('denuncias-store-module/updateArchivosDenuncia', data)
        .then(() => {
          this.$emit('actualizarTablaDenuncias')
          this.$bvModal.hide('modal_actualizar_estado')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Estado actualizado correctamente!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error en actualizar el estado!',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
