import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getActividades(ctx, idDenuncia) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/prevencion-hostigamiento/denuncias/${idDenuncia}/seguimiento`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addActividadSeguimiento(ctx, { idDenuncia, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/prevencion-hostigamiento/denuncias/${idDenuncia}/seguimiento`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateActividad(ctx, { idActividad, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-social/prevencion-hostigamiento/denuncias/${idActividad}`, data)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    /* evidencias */
    getEvidencias(ctx, { actividadId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/prevencion-hostigamiento/seguimiento/${actividadId}/evidencias`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvidencia(ctx, { actividadId, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/prevencion-hostigamiento/seguimiento/${actividadId}/evidencias`, formData)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    deleteEvidencia(ctx, { actividadId, id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-social/prevencion-hostigamiento/seguimiento/${actividadId}/evidencias/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
