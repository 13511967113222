<template>
  <b-modal
    id="modal_formulario_lectura_denuncia"
    title="Informe de pre calificación del comité"
    size="lg"
    :hide-footer="true"
  >
    <formulario-vista-lectura
      :id-usuario="usuarioAnonimo"
      :id-formulario="idFormulario"
    />
  </b-modal>
</template>

<script>

import FormularioVistaLectura from '@/views/administrador/gestion-formulario/vista-lectura/FormularioVistaLectura.vue'

export default {
  components: {
    FormularioVistaLectura,
  },
  data() {
    return {
      usuarioAnonimo: '',
      idFormulario: 0,
    }
  },
  created() {
    this.$parent.$on('abrirModalFormularioLectura', this.abrirModalFormularioLectura)

    this.$http.get('/formulario/HOSTIGAMIENTO-DENUNCIA/buscar')
      .then(res => {
        this.idFormulario = res.data.id
      })
  },

  methods: {
    abrirModalFormularioLectura(usuarioAnonimo) {
      this.usuarioAnonimo = usuarioAnonimo
      this.$bvModal.show('modal_formulario_lectura_denuncia')
    },

  },

}
</script>
